<template>
  <div>
    <WrapperSubHeader>
      <span>Accounts</span>
    </WrapperSubHeader>
    <div class="container-xl py-2 mx-auto w-full my-4 flex flex-col">
      <div class="flex bg-gray-200 md:rounded-lg my-5 py-3 md:py-0">
        <div class="w-1/3 px-4 py-2 md:py-5">
          <label class="label pt-0">
            <span class="label-text">Keyword</span>
          </label>
          <input type="text" placeholder="ID, Proyecto, Canal, Servicio..." class="dl-input bg-white" v-model="filterKeyword" v-debounce:700ms="search" />
        </div>
        <div class="w-1/3 px-4 self-center mt-6">
          <multiselect v-model="filter.state" :options="states" searchable placeholder="Seleccionar Estado"></multiselect>
        </div>
        <div class="self-center mt-6">
          <button class="btn btn-success btn-xs no-animation w-max self-center" style="height: fit-content" @click="search()">{{ $t("accounts.search") }}</button>
          <button class="btn btn-error btn-xs w-max self-center ml-3" style="height: fit-content" v-if="filterActive" @click="cleanFilter()">{{ $t("accounts.deleteFilter") }}</button>
        </div>
      </div>
      <div class="w-full mb-20">
        <div class="mx-auto">
          <div class="flex flex-col">
            <div v-if="loading" class="mt-12 w-full flex items-center justify-center">
              <Loader :onlyLoader="true" />
            </div>
            <div class="overflow-x-auto" v-if="!loading">
              <PaginationSelect :current_page="page.current_page" :from="page.from" :last_page="page.last_page" :path="page.path" :per_page="page.per_page" :to="page.to" :total="page.total" @change-page="paginationChange" />
              <div class="pt-2 pb-40 align-middle inline-block min-w-full" v-if="accounts.length">
                <div class="overflow-visible border border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200 table-fixed">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.id") }}</th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.name") }}</th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.email") }}</th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.state") }}</th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.project") }}</th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.channelInput") }}</th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.channelOutput") }}</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr @click="showModalDetail(account)" v-for="(account, accountIndex) in accounts" :key="accountIndex" class="transition cursor-pointer hover:bg-gray-100">
                        <td class="px-5 py-3">
                          <span class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-900"> #{{ account.id }} </span>
                        </td>
                        <td class="px-5 py-3">
                          <div class="text-sm text-gray-700 font-semibold inline-block break-words">{{ account.name }}</div>
                        </td>
                        <td class="px-5 py-3">
                          <div class="text-sm text-gray-700 font-semibold inline-block break-words">{{ account.email }}</div>
                        </td>
                        <td class="px-5 py-3">
                          <span v-if="account.state == 'PENDING'" class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-200 text-gray-800 uppercase">{{ account.state }}</span>
                          <span v-if="account.state == 'CONFIG'" class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-200 text-gray-800 uppercase">{{ account.state }}</span>
                          <span v-if="account.state == 'SUCCESS'" class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-200 text-gray-800 uppercase">{{ account.state }}</span>
                          <span v-if="account.state == 'FAILED'" class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-200 text-gray-800 uppercase">{{ account.state }}</span>
                        </td>
                        <td class="px-5 py-3">
                          <div class="text-sm text-gray-700 font-semibold inline-block break-word">{{ account.project ? account.project.name : "-" }}</div>
                        </td>
                        <td class="px-5 py-3">
                          <div class="text-sm text-gray-700 font-semibold inline-block break-word">{{ account.channel_input ? account.channel_input.name : "-" }}</div>
                        </td>
                        <td class="px-5 py-3">
                          <div class="text-sm text-gray-700 font-semibold inline-block break-words">{{ account.channel_output ? account.channel_output.name : "-" }}</div>
                        </td>
                      </tr>
                    </tbody>
                    <Dialog header="Details" v-model:visible="showDetail" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '30vw' }">
                      <div class="w-full justify-around">
                        <div class="px-6 w-full flex items-end justify-start divide-y-2 divide-dashed divide-gray-400">
                          <h2 class="text-left text-xs lg:text-base card-title text-gray-500">#{{ accountSelect.id }} - {{ accountSelect.name }}</h2>
                        </div>
                        <div class="flex flex-col mt-2">
                          <div v-if="accountSelect.data_output">
                            <div v-if="accountSelect.data_output.request" class="flex flex-row justify-start items-center">
                              <h2 class="w-28 px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.country") }}</h2>
                              <p class="text-sm text-gray-800">{{ accountSelect.data_output.language }}</p>
                            </div>
                            <div v-if="accountSelect.data_output.company" class="flex flex-row justify-start items-center">
                              <h2 class="w-28 px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.company") }}</h2>
                              <p class="text-sm text-gray-800">{{ accountSelect.data_output.company }}</p>
                            </div>
                          </div>
                          <div class="flex flex-row justify-start items-center">
                            <h2 class="w-28 px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.name") }}</h2>
                            <p class="text-sm text-gray-800">{{ accountSelect.name }}</p>
                          </div>
                          <div class="flex flex-row justify-start items-center">
                            <h2 class="w-28 px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.email") }}</h2>
                            <p class="text-sm text-gray-800">{{ accountSelect.email }}</p>
                          </div>
                          <div v-if="accountSelect.data_output">
                            <div v-if="accountSelect.data_output.request" class="flex flex-row justify-start items-center">
                              <h2 class="w-28 px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.phone") }}</h2>
                              <p class="text-sm text-gray-800">{{ accountSelect.data_output.request.PhoneNumber }}</p>
                            </div>
                            <div v-if="accountSelect.data_output.request" class="flex flex-row justify-start items-center">
                              <h2 class="w-28 px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.numberContacts") }}</h2>
                              <p class="text-sm text-gray-800">{{ accountSelect.data_output.number_contacts }}</p>
                            </div>
                            <div v-if="accountSelect.data_output.request" class="flex flex-row justify-start items-center">
                              <h2 class="w-28 px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.language") }}</h2>
                              <p class="text-sm text-gray-800">{{ accountSelect.data_output.request.Language }}</p>
                            </div>
                            <div v-if="accountSelect.data_output.request" class="flex flex-row justify-start items-center">
                              <h2 class="w-28 px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.ipAddress") }}</h2>
                              <p class="text-sm text-gray-800">{{ accountSelect.data_output.request.IpAddress }}</p>
                            </div>
                            <div v-if="accountSelect.data_output.error" class="flex flex-row justify-start items-center my-2">
                              <h2 class="w-28 px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.error") }}</h2>
                              <small v-if="accountSelect.data_output.error" class="break-all text-sm text-gray-800">
                                {{ accountSelect.data_output.error.message }}
                              </small>
                            </div>
                            <div v-if="accountSelect.data_output.error" class="flex flex-row justify-start items-center">
                              <h4 class="w-28 px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.error") }}</h4>
                              <small v-if="accountSelect.data_output.error" class="break-all text-left text-sm text-gray-800">
                                {{ accountSelect.data_output.error.response }}
                              </small>
                            </div>
                          </div>
                          <div class="flex flex-row justify-start items-center">
                            <h2 class="w-28 px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.created") }}</h2>
                            <p class="text-sm text-gray-800">{{ $moment(accountSelect.created_at).format("YYYY-MM-DD HH:mm") }} - {{ $moment(accountSelect.created_at).fromNow(true) }}</p>
                          </div>
                          <div class="flex flex-row justify-start items-center">
                            <h2 class="w-28 px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.uuid") }}</h2>
                            <p class="text-sm text-gray-800">#{{ accountSelect.uuid }}</p>
                          </div>
                        </div>
                      </div>
                    </Dialog>
                  </table>
                </div>
              </div>
              <div class="pt-2 pb-40 align-middle inline-block min-w-full sm:px-6 lg:px-8" v-else>
                <div class="flex flex-col items-center mt-20">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-32 text-gray-300">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                  <span class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.notfound") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WrapperSubHeader from "@/components/WrapperSubHeader"
import PaginationSelect from "@/components/PaginationSelect"
import Multiselect from "@vueform/multiselect"
import Loader from "@/components/UI/Loader"
import axiosClient from "@/config/AxiosClient"
import Dialog from "primevue/dialog"

export default {
  name: "Accounts",
  components: {
    WrapperSubHeader,
    PaginationSelect,
    Loader,
    Multiselect,
    Dialog
  },
  data() {
    return {
      default: {
        limit: 50
      },
      filterKeyword: "",
      accounts: [],
      filter: {
        state: null
      },
      page: {},
      states: ["PENDING", "FAILED", "SUCCESS"],
      filterActive: false,
      loading: true,
      showModalData: false,
      modal: {
        title: "",
        data: {}
      },
      showDetail: false,
      accountSelect: {}
    }
  },
  methods: {
    async search(config = {}) {
      let filter = {}

      if (this.filterKeyword) {
        filter["filter[uuid|name|email|state|data_input|data_output|project.name|channel_input.name|channel_output.name|user.name|created_at|updated_at][like]"] = `%${this.filterKeyword}%`
        this.filterActive = true
      }

      filter.page = config.page || 1
      filter.limit = config.limit || this.default.limit
      if (this.filter.state && this.filter.state != "") {
        filter["filter[state][=]"] = this.filter.state
        this.filterActive = true
      }
      filter.sort = "-created_at"
      let header = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`
        }
      }
      let {
        data: { data, meta }
      } = await axiosClient.get(`/api/2.0/admin/accounts`, header)
      this.accounts = data
      this.page = meta
      this.loading = false
    },
    paginationChange: function (page) {
      this.search({ page })
    },
    cleanFilter() {
      this.filterKeyword = ""
      Object.keys(this.filter).forEach((key) => {
        this.filter[key] = null
      })
      this.filterActive = false
      this.search()
    },
    showModalDetail: function (accountSelect) {
      this.accountSelect = accountSelect
      this.showDetail = true
    }
  },
  async created() {
    await this.search()
  }
}
</script>
